<template>
  <div class="gokito" id="gokito">
    <Nav/>
      <main id="main" role="main">
        <section>
            <h2><img src="@/assets/img/head_gokito.svg" alt="ご祈祷"></h2>
            <div class="box">
              <ul class="square">
                <li>予約優先になっております。</li>
                <li>外祭に出ている場合もございますので、できる限りお電話にてご予約をお願いいたします。</li>
              </ul>
            </div>

            <div class="box"><p>熊野皇大神社では一組ずつ丁寧に皆さまのご祈願をさせていただいております。祝詞を奏上し、神前にてお祈り申し上げ、ご神徳をいただけるようにご祈祷いたします。</p></div>
            <div class="box">
              <ul class="square">
                <li>場所 皇大殿</li>
                <li>初穂料 個人の方  6,000円からお気持ちでお納めいただきます。<br>
                    法人の方  10,000円からお気持ちでお納めいただきます。</li>
              </ul>
            </div>

            <div class="box">
              <ul>
                <li><p>新年祈願</p></li>
                <li><p>家内安全祈願</p></li>
                <li><p>商売繁盛祈願</p></li>
                <li><p>厄祓・方位除・車祓（交通安全）</p></li>
                <li><p>安全祈願</p></li>
                <li><p>開運招福</p></li>
                <li><p>安産祈願</p></li>
                <li><p>初宮参り・七五三参り・十三参り</p></li>
                <li><p>合格祈願・心願成就</p></li>
                <li><p>神恩感謝</p></li>
                <li><p>結婚式</p></li>
              </ul>
              <p>その他、各種ご祈祷承ります。</p>
            </div>

            <div class="box">
              <slick ref="slick" :options="slickOptions">
                <a href=""><img src="@/assets/img/gokito01.jpg" alt="ご祈祷"></a>
                <a href=""><img src="@/assets/img/gokito02.jpg" alt="ご祈祷"></a>
              </slick>
            </div>
          </section>

          <section>
            <h2><img src="@/assets/img/head_inquiry.svg" alt="お申し込み"></h2>
            <div class="box"><p>必ずお電話にてお申し込みください。ご希望の日時をお伺いいたします。</p>
              <p>TEL. <a class="tel" href="tel:0267425749">0267-42-5749</a></p>
              <p>FAX. 0267-42-1505</p>
            </div>
          </section>
      </main>
    <Foot/>
  </div>
</template>

<script>
import Foot from '@/components/Foot.vue'
import Nav from '@/components/Nav.vue'

import Slick from 'vue-slick'
import '../../node_modules/slick-carousel/slick/slick.css'

export default {
  name: 'Goshinboku',
  components: {
    Foot,
    Nav,
    Slick
  },

  data () {
    return {
      slickOptions: {
        dots: true,
        arrows: false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  p,
  ul {
      font-family: 'NuKinakoMochi-BarVtE-1'
  }
  .box {
    ul,
    p{
      margin-bottom:0;
    }
    ul {
      li {
        display: inline-block;
      }
    }
  }
  h3 {
    margin-bottom: 0;
  }
  .tel {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
  }
</style>
